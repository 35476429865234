import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
})
export class DateRangeFilterComponent {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() valueChanges = new EventEmitter<Date[]>();

  formGroup = new FormGroup({
    dateRange: new FormControl<Date[]>([]),
  });

  constructor() {
    this.formGroup.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((value) => {
        let dateRange = value.dateRange;
        if (dateRange && dateRange.length > 0) {
          const min = DateTime.fromJSDate(dateRange[0]).startOf('day').toJSDate();
          const max = DateTime.fromJSDate(dateRange[1]).endOf('day').toJSDate();
          dateRange = [min, max];
        }
        this.valueChanges.emit(dateRange ?? []);
      });
  }

  resetFilters() {
    this.formGroup.reset();
  }
}
