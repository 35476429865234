import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { getAlertStatusChipColor, getContextMenusByAlertStatus } from '@dpdhl-iot/alert';
import {
  AlertActionModel,
  AlertActionType,
  AlertStatusType,
  AlertViewModel,
  DefaultViewConfiguration,
  UpdateAlertStatusRequestArgs,
  ViewConfigurationModel,
} from '@dpdhl-iot/shared';
import {
  CheckboxGroupItem,
  PaginationConfig,
  PaginationStorageType,
  TableColumn,
  TableColumnSortConfig,
  TableComponent,
  TableSortDirection,
  TableSortingConfig,
} from '@dpdhl/angular-shared-ui';
import { TableDownloadConfig } from '@dpdhl/angular-shared-ui/lib/table/table.types';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-alert-list-table',
  templateUrl: './alert-list-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertListTableComponent implements OnChanges {
  @Input() alertData: AlertViewModel[] = [];
  @Input() showAreaLink = false;
  @Input() workFlowFacilities: string[] = [];
  @Input() hasAlertUpdatePermissionFacilities: string[] = [];
  @Input() viewConfiguration: ViewConfigurationModel = DefaultViewConfiguration;
  @Input() isLoading = true;
  @Input() selectableFacilities: string[] = [];
  @Input() alertAction: AlertActionModel | undefined = undefined;
  @Output() selectAlertAction = new EventEmitter<AlertActionModel>();
  @Output() updatedAlertWorkflow = new EventEmitter<UpdateAlertStatusRequestArgs>();
  @Output() closeAlertWorkflow = new EventEmitter();
  @ViewChild('table') table!: TableComponent;
  @ViewChild('dateColumn') dateColumn!: TableColumn;

  filterDeviceIds$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterDeviceNames$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterCountryNames$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterLocations$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterFacilityNames$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterAlertTypes$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterSeverities$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterStatusIds$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterComments$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  filterThresholds$ = new ReplaySubject<CheckboxGroupItem[]>(1);
  minDateFilter: Date;
  maxDateFilter: Date;

  AlertActionType = AlertActionType;
  showWorkflowDialog = false;
  activeAlertStatus = ['Open', 'Accepted', 'Resolving'];
  downloadConfig: TableDownloadConfig = {
    fileName: 'AlertList',
    sheetName: this.alertAction?.action,
  };
  paginationConfig = {
    pageSize: 20,
    enablePageSizeSelection: true,
    storageType: PaginationStorageType.LOCAL_STORAGE,
  } as PaginationConfig;
  sortingConfig: TableSortingConfig = {
    defaultSortField: 'severity',
    defaultSortDirection: TableSortDirection.ASCENDING,
  };
  valueSortConfig: TableColumnSortConfig = {
    sort: (a: string, b: string) => {
      const x = parseInt(a, 10);
      const y = parseInt(b, 10);
      if (isNaN(x) && !isNaN(y)) {
        return 1;
      }
      if (!isNaN(x) && isNaN(y)) {
        return -1;
      }
      if (isNaN(x) && isNaN(y)) {
        return a.localeCompare(b);
      }
      return x - y;
    },
  };
  severitySortConfig: TableColumnSortConfig = {
    sort: (a: string, b: string) => {
      const x = this.severitiesMap.get(a) ?? 4;
      const y = this.severitiesMap.get(b) ?? 4;
      return x - y;
    },
  };
  private readonly severitiesMap = new Map<string, number>()
    .set('High', 1)
    .set('Alert', 1)
    .set('Normal', 2)
    .set('Warning', 2)
    .set('Low', 3);

  constructor(private readonly translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewConfiguration?.currentValue) {
      this.downloadConfig = {
        fileName: 'AlertList',
        sheetName: this.alertAction?.action,
        header: this.viewConfiguration.alert.headers,
        toRowEntry: this.viewConfiguration.alert.toRowEntry,
      };
    }

    if (changes.alertData && !changes.alertData.isFirstChange() && changes.alertData.currentValue) {
      this.filterCountryNames$.next(this.getFilterOptions('countryName'));
      this.filterDeviceIds$.next(this.getFilterOptions('deviceId'));
      this.filterDeviceNames$.next(this.getFilterOptions('deviceName'));
      this.filterLocations$.next(this.getFilterOptions('location'));
      this.filterFacilityNames$.next(this.getFilterOptions('facilityName'));
      this.filterAlertTypes$.next(this.getFilterOptions('alertType', 'room.chart.'));
      this.filterSeverities$.next(this.getFilterOptions('severity', 'alert.severity.'));
      this.filterStatusIds$.next(this.getFilterOptions('statusId', 'dashboard.alert.'));
      this.filterComments$.next(this.getFilterOptions('remarks'));
      this.filterThresholds$.next(this.getFilterOptionsThreshold());
      this.minDateFilter = new Date(Math.min(...this.alertData.map((x) => x.deviceTimestamp)));
      this.maxDateFilter = new Date();
    }
  }

  allowWorkflowAction(alertModel: AlertViewModel) {
    return (
      this.workFlowFacilities.length > 0 ||
      (this.hasAlertUpdatePermission(alertModel) &&
        this.alertData.some((alert) => this.activeAlertStatus.includes(alert.statusId.toString())))
    );
  }
  allowAlertWorkflow(alert: AlertViewModel): boolean {
    return this.allowManuallyClose(alert) || this.allowWorkflowFacility(alert.facilityId);
  }

  allowWorkflowFacility(facilityId?: string): boolean {
    return this.workFlowFacilities.some((facility) => facility === facilityId);
  }

  allowManuallyClose(alert: AlertViewModel): boolean {
    return (
      this.hasAlertUpdatePermission(alert) &&
      this.activeAlertStatus.includes(alert.statusId.toString())
    );
  }

  getAlertStatusChipColor(statusId: AlertStatusType): string {
    return getAlertStatusChipColor(statusId);
  }

  getActions(alertDetail: AlertViewModel): AlertActionType[] {
    let action: AlertActionType[] = [];
    if (this.allowAlertWorkflow(alertDetail)) {
      action = getContextMenusByAlertStatus(alertDetail.statusId);
      if (
        !this.hasAlertUpdatePermission(alertDetail) &&
        this.allowWorkflowFacility(alertDetail.facilityId)
      ) {
        action = action.filter((context) => context !== AlertActionType.Close);
      } else if (
        this.allowManuallyClose(alertDetail) &&
        !this.allowWorkflowFacility(alertDetail.facilityId)
      ) {
        action = [AlertActionType.Close];
      }
    }
    return action;
  }

  hasAlertUpdatePermission(alert: AlertViewModel): boolean {
    return this.hasAlertUpdatePermissionFacilities.some(
      (facility) => facility === alert.facilityId,
    );
  }

  onActionClick(args: AlertActionModel) {
    this.selectAlertAction.emit(args);
    this.showWorkflowDialog = true;
  }

  onUpdateAlertStatus(args: UpdateAlertStatusRequestArgs) {
    this.updatedAlertWorkflow.emit(args);
    this.onCloseAlertStatus();
  }

  onCloseAlertStatus() {
    this.showWorkflowDialog = false;
    this.closeAlertWorkflow.emit();
  }

  onDateRangeFilterChange(dateRange: Date[]) {
    if (dateRange && dateRange.length > 0) {
      const fromDate = dateRange[0].getTime();
      const toDate = dateRange[1].getTime();
      const filteredDates = this.alertData
        .filter((o) => {
          return o.deviceTimestamp >= fromDate && o.deviceTimestamp <= toDate;
        })
        .map((o) => o.deviceTimestamp);
      if (filteredDates.length == 0) {
        filteredDates.push(fromDate, toDate);
      }
      this.table.onFilter({
        column: this.dateColumn,
        selectedItemValues: filteredDates,
      });
    } else {
      this.table.onFilter({
        column: this.dateColumn,
        selectedItemValues: [],
      });
    }
    this.table._ref.detectChanges();
  }

  getFilterOptions(fieldName: keyof AlertViewModel, langKey = '') {
    const params = this.alertData
      .map((detail) => detail[fieldName])
      .filter((prop): prop is string => !!prop);
    const uniqueParams = [...new Set(params)];
    uniqueParams.sort((a, b) => a.localeCompare(b));
    return uniqueParams.map((id) => ({
      label: langKey ? this.translate.instant(langKey + id.toLowerCase()) : id,
      value: id,
    }));
  }

  getFilterOptionsThreshold() {
    const params = this.alertData
      .map((detail) => detail.threshold)
      .filter((prop): prop is string => !!prop);
    const uniqueParams = [...new Set(params)];
    uniqueParams.sort(
      (a, b) =>
        (isNaN(+a) ? Infinity : Number(a)) - (isNaN(+b) ? Infinity : Number(b)) ||
        a.localeCompare(b),
    );
    return uniqueParams.map((id) => ({
      label: id,
      value: id,
    }));
  }
}
