<div class="space-y-16">
  <div class="flex justify-end">
    <app-date-range-filter
      [minDate]="minDateFilter"
      [maxDate]="maxDateFilter"
      (valueChanges)="onDateRangeFilterChange($event)"
    ></app-date-range-filter>
  </div>

  <div>
    <dpdhl-table
      #table
      [data]="{ elements: alertData }"
      [loading]="isLoading"
      [downloadConfig]="downloadConfig"
      [paginationConfig]="paginationConfig"
      [sorting]="sortingConfig"
      [withActionRow]="{
        showSearch: false,
        showExport: true,
      }"
      prefix="alerts"
    >
      <ng-container *ngIf="viewConfiguration.alert.showColumn_Device">
        <dpdhl-table-column
          field="deviceId"
          header="{{ 'dashboard.alert.deviceId' | translate }}"
          [customTemplate]="customDeviceId"
          [filterable]="{ items$: filterDeviceIds$ }"
        >
          <ng-template #customDeviceId let-alert="row">
            <a
              [ngClass]="
                selectableFacilities.includes(alert.facilityId)
                  ? 'pointer-events-auto'
                  : 'pointer-events-none'
              "
              routerLink="{{
                [
                  '/use-cases/' +
                    viewConfiguration.application.id +
                    '/facilities/' +
                    alert.facilityId +
                    '/' +
                    alert.areaId,
                ]
              }}"
            >
              <span> {{ alert.deviceId }}</span></a
            >
          </ng-template>
        </dpdhl-table-column>
        <dpdhl-table-column
          field="deviceName"
          header="{{ 'dashboard.alert.deviceName' | translate }}"
          [filterable]="{ items$: filterDeviceNames$ }"
        ></dpdhl-table-column>
      </ng-container>
      <dpdhl-table-column
        field="countryName"
        *ngIf="viewConfiguration.alert.showColumn_Country"
        header="{{ 'dashboard.filter.country' | translate }}"
        [sortConfig]="'Text' | sortingConfig"
        [filterable]="{ items$: filterCountryNames$ }"
      ></dpdhl-table-column>
      <dpdhl-table-column
        field="location"
        header="{{ viewConfiguration.areaView.localizations.area | translate }}"
        [customTemplate]="customLocation"
        [filterable]="{ items$: filterLocations$ }"
        *ngIf="showAreaLink && viewConfiguration.alert.showColumn_Location"
      >
        <ng-template #customLocation let-alert="row">
          <a
            [ngClass]="
              selectableFacilities.includes(alert.facilityId)
                ? 'pointer-events-auto'
                : 'pointer-events-none'
            "
            routerLink="{{
              [
                '/use-cases/' +
                  viewConfiguration.application.id +
                  '/facilities/' +
                  alert.facilityId +
                  '/' +
                  alert.areaId,
              ]
            }}"
          >
            <span [title]="alert.location">{{ alert.location }}</span></a
          >
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="facilityName"
        header="{{ 'dashboard.alert.facility' | translate }}"
        [customTemplate]="customFacility"
        [filterable]="{ items$: filterFacilityNames$ }"
        *ngIf="!showAreaLink && viewConfiguration.alert.showColumn_FacilityName"
      >
        <ng-template #customFacility let-alert="row">
          <a
            [ngClass]="
              selectableFacilities.includes(alert.facilityId)
                ? 'pointer-events-auto'
                : 'pointer-events-none'
            "
            routerLink="{{
              ['/use-cases/' + viewConfiguration.application.id + '/facilities/' + alert.facilityId]
            }}"
          >
            <span [title]="alert.facilityName">{{ alert.facilityName }}</span></a
          >
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        #dateColumn
        field="deviceTimestamp"
        header="{{ 'dashboard.alert.deviceTimestamp' | translate }}"
        [sortConfig]="'Timestamp' | sortingConfig"
        [customTemplate]="customDate"
      >
        <ng-template #customDate let-alert="row">
          <span [title]="alert.deviceTimestamp | date: 'dd.MM.yyyy HH:mm'">{{
            alert.deviceTimestamp | date: 'dd.MM.yyyy HH:mm'
          }}</span>
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="alertType"
        header="{{ 'dashboard.alert.alertType' | translate }}"
        [customTemplate]="customAlertType"
        [filterable]="{ items$: filterAlertTypes$ }"
      >
        <ng-template #customAlertType let-alert="row">
          <div *ngIf="alert.alertType" class="flex items-center space-x-8">
            <svg-icon
              svgClass="w-16 h-16"
              src="assets/images/CoMo/{{ alert.alertType.toLowerCase() }}-default-icon.svg"
            ></svg-icon>
            <span [title]="alert.alertTypeText">{{ alert.alertTypeText }}</span>
          </div>
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="severity"
        header="{{ 'dashboard.alert.severity' | translate }}"
        [sortConfig]="severitySortConfig"
        [customTemplate]="customSeverity"
        [filterable]="{ items$: filterSeverities$ }"
      >
        <ng-template #customSeverity let-alert="row">
          <div *ngIf="alert.severity" class="flex items-center space-x-8">
            <img
              *ngIf="alert.severity?.toLowerCase() === 'high'"
              class="info"
              alt="info"
              style="width: 2px; height: 16px"
              src="assets/images/CoMo/severity-{{ alert.severity?.toLowerCase() }}-icon.png"
            />
            <span [title]="alert.severityText">{{ alert.severityText }}</span>
          </div>
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="threshold"
        *ngIf="viewConfiguration.alert.showColumn_Threshold"
        header="{{ 'dashboard.alert.threshold' | translate }}"
      ></dpdhl-table-column>
      <dpdhl-table-column
        field="threshold"
        [sortConfig]="valueSortConfig"
        *ngIf="viewConfiguration.alert.showColumn_Value"
        [filterable]="{ items$: filterThresholds$ }"
        header="{{ 'dashboard.alert.value' | translate }}"
      ></dpdhl-table-column>
      <dpdhl-table-column
        field="statusId"
        header="{{ 'dashboard.alert.status' | translate }}"
        [customTemplate]="customStatus"
        [filterable]="{ items$: filterStatusIds$ }"
      >
        <ng-template #customStatus let-alert="row">
          <div class="flex space-x-4 items-center">
            <div
              class="dpdhl-rounded-8 text-center px-8 py-8 text-body2 {{
                getAlertStatusChipColor(alert.statusId)
              }}"
            ></div>
            <span class="dpdhl-chip-content">{{
              'dashboard.alert.' + alert.statusId.toLowerCase() | translate
            }}</span>
          </div>
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="remarks"
        header="{{ 'alert.filterCol.lastComment' | translate }}"
        [sortable]="false"
        *ngIf="viewConfiguration.alert.showColumn_LastComment"
        [customTemplate]="lastComment"
        [filterable]="{ items$: filterComments$ }"
      >
        <ng-template #lastComment let-alert="row">
          <span title="{{ alert.remarks }}" class="break-normal" data-testid="spanLastComment">
            {{ alert.remarks }}
          </span>
        </ng-template>
      </dpdhl-table-column>
      <dpdhl-table-column
        field="action"
        header="{{ 'device.grid.action' | translate }}"
        [sortable]="false"
        [customTemplate]="customAction"
      >
        <ng-template #customAction let-alert="row">
          <div class="flex mt-4">
            <div
              class="cursor-pointer ml-12"
              title="{{ 'dashboard.alert.context.view' | translate }}"
              (click)="onActionClick({ action: AlertActionType.View, alert })"
            >
              <svg-icon src="/assets/images/CoMo/view-details.svg" svgClass="h-12"></svg-icon>
            </div>
            <ng-container *ngIf="allowWorkflowAction(alert) && allowAlertWorkflow(alert)">
              <ng-container *ngFor="let action of getActions(alert)">
                <div
                  class="cursor-pointer ml-12"
                  title="{{ 'dashboard.alert.context.' + action.toLowerCase() | translate }}"
                  (click)="onActionClick({ action, alert })"
                >
                  <svg-icon
                    *ngIf="action === 'Comment'"
                    src="/assets/images/CoMo/comments_alert.svg"
                    svgClass="h-12"
                  ></svg-icon>
                  <svg-icon
                    *ngIf="['Accept', 'Resolving'].includes(action)"
                    src="/assets/images/CoMo/accept.svg"
                    svgClass="h-12"
                  ></svg-icon>
                  <svg-icon
                    *ngIf="action === 'Close'"
                    src="/assets/images/CoMo/close_manually.svg"
                    svgClass="h-12"
                  ></svg-icon>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </dpdhl-table-column>
    </dpdhl-table>
  </div>
</div>
@if (alertData?.length > 0) {
  <app-alert-workflow
    [alert]="alertAction?.alert"
    [action]="alertAction?.action"
    [open]="showWorkflowDialog"
    (closeDialog)="onCloseAlertStatus()"
    (updateAlertStatus)="onUpdateAlertStatus($event)"
  ></app-alert-workflow>
}
