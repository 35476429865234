import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CellAlertStatus, LoadImage } from '@dpdhl-iot/predictive-maintenance';
import { AlertRemarkViewModel, AlertStatusType } from '@dpdhl-iot/shared';
import { SvgIconComponent } from '@dpdhl/angular-shared-ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, debounceTime, Subject } from 'rxjs';
import { FrequencyDiagramComponent } from '../../frequency-diagram/frequency-diagram.component';
import { CellAnalysisDiagramModel } from '../cell-analysis-diagram-model';

@Component({
  standalone: true,
  selector: 'app-cell-analysis',
  templateUrl: './cell-analysis.component.html',
  styleUrls: ['./cell-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [CommonModule, SvgIconComponent, TranslateModule, FrequencyDiagramComponent],
})
export class CellAnalysisComponent implements OnChanges {
  @Input() diagramModel: CellAnalysisDiagramModel;
  @Input() cellFrequencyData: ArrayBuffer | null = null;
  @Input() showFrequencyAxis = true;

  @ViewChild('frequencyImage', { read: ElementRef }) frequencyImageElem: ElementRef;

  private readonly mouseMoveSubject = new Subject<MouseEvent | undefined>();
  private readonly marginMap = new Map<string, number>();
  mouseOverText = '';
  loading = true;
  diagramLoading = true;

  private readonly loadFrequencyDiagramProvider = new BehaviorSubject<LoadImage>({
    isLoading: true,
    imageData: null,
  });
  // eslint-disable-next-line @typescript-eslint/member-ordering
  loadFrequencyDiagram = this.loadFrequencyDiagramProvider.asObservable();

  constructor(private readonly translateService: TranslateService) {
    this.mouseMoveSubject
      .pipe(debounceTime(100), takeUntilDestroyed())
      .subscribe((event) => this.overlayDateMouseMove(event));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cellFrequencyData?.currentValue) {
      this.loadFrequencyDiagramProvider.next({
        isLoading: false,
        imageData: this.cellFrequencyData,
      });
      this.loading = false;
    }
  }

  mouseMove(event: MouseEvent) {
    this.mouseMoveSubject.next(event);
  }

  overlayDateMouseLeave() {
    this.mouseMoveSubject.next(undefined);
    this.mouseOverText = '';
  }

  overlayDateMouseMove(event?: MouseEvent) {
    this.mouseOverText = '';
    if (!event) {
      return;
    }
    if (event.target instanceof Image && event.target.alt === 'frequency-diagram') {
      // Calculate Date to show underneath the frequency diagram
      this.mouseOverText = this.computeMouseOverText(
        event.offsetX,
        event.offsetY,
        event.target.width,
        event.target.height,
        this.diagramModel.frequencyDataCellTimes,
        this.diagramModel.frequencyRange,
      );
    }
  }

  private computeMouseOverText(
    offsetX: number,
    offsetY: number,
    imageWidth: number,
    imageHeight: number,
    frequencyDataCellTimes: number[],
    frequencyRange: number[],
  ): string {
    let mouseOverText = '';
    const timeOffset = Math.trunc((frequencyDataCellTimes.length * offsetX) / imageWidth);
    const time = frequencyDataCellTimes[timeOffset];
    const timeText = time ? DateTime.fromMillis(time).toFormat('dd.MM.yyyy HH:mm:ss') : '';

    // Calculate frequencies to show underneath the frequency diagram
    const frequencyOffset =
      frequencyRange.length -
      (Math.trunc(((frequencyRange.length - 1) * offsetY) / imageHeight) + 2);
    const frequencyRange1 = frequencyRange[frequencyOffset];
    const frequencyRange2 = frequencyRange[frequencyOffset + 1];
    if (timeText && frequencyRange1 && frequencyRange2) {
      mouseOverText = `${timeText}  -  ${frequencyRange1}-${frequencyRange2} Hz`;
    } else if (timeText && isNaN(frequencyRange1) && isNaN(frequencyRange2)) {
      mouseOverText = `${timeText} - ${this.translateService.instant('predictiveMaintenance.frequencyAnalysis.totalLimitAlert')}`;
    } else if (timeText && isNaN(frequencyRange2)) {
      mouseOverText = `${timeText} - ${this.translateService.instant('predictiveMaintenance.frequencyAnalysis.cellLimitAlert')}`;
    }
    return mouseOverText;
  }

  getMarginForImage(date: string): number {
    if (this.marginMap.has(date)) {
      return this.marginMap.get(date) as number;
    }

    const imageWidth = this.frequencyImageElem.nativeElement.querySelector('img')?.offsetWidth;
    if (!imageWidth || imageWidth === 1000) {
      return 0;
    }

    const elementWidth = this.frequencyImageElem.nativeElement.offsetWidth;
    const widthDiff = elementWidth - imageWidth;

    const times = this.diagramModel.frequencyDataCellTimes.filter(
      (a) => DateTime.fromMillis(a).startOf('day').toMillis() === +date,
    );
    const middleIndex = times
      .map((a) => this.diagramModel.frequencyDataCellTimes.indexOf(a))
      .reduce((acc, v, _i, a) => acc + v / a.length, 0);

    const margin =
      widthDiff -
      10 +
      Math.trunc((middleIndex * imageWidth) / this.diagramModel.frequencyDataCellTimes.length);
    this.marginMap.set(date, margin);

    return margin;
  }

  getCountForAlertType(alertStatus: CellAlertStatus, alertRemarks: AlertRemarkViewModel[]): number {
    switch (alertStatus) {
      case CellAlertStatus.NewAlert:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks.startsWith('Alert Opened by System'),
        ).length;
      case CellAlertStatus.NewWarning:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks.startsWith('Warning Opened by System'),
        ).length;
      case CellAlertStatus.SeverityToAlert:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks == 'Severity changed to Alert',
        ).length;
      case CellAlertStatus.SeverityToWarning:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks == 'Severity changed to Warning',
        ).length;
      case CellAlertStatus.AutomaticallyResolved:
        return alertRemarks.filter((remark) => remark.statusId == AlertStatusType.Resolved).length;
      case CellAlertStatus.ManuallyResolved:
        return alertRemarks.filter((remark) => remark.statusId == AlertStatusType.ManuallyClosed)
          .length;
      default:
        return 0;
    }
  }

  onDiagramLoad() {
    this.diagramLoading = false;
  }

  protected readonly CellAlertStatus = CellAlertStatus;
}
