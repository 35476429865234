import { Injectable } from '@angular/core';
import { IotApplicationModel } from '@dpdhl-iot/api/management';
import { DeviceProviderModel, DeviceProvidersService } from '@dpdhl-iot/api/preprocessor';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { distinctUntilChanged, filter, map, mergeMap, of, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CoreConstants } from '../../constants/core-constants';

@Injectable({
  providedIn: 'root',
})
export class DeviceProvidersDataService {
  private readonly _deviceProviders$: ReplaySubject<DeviceProviderModel[]> = new ReplaySubject(1);

  constructor(
    private readonly deviceProvidersService: DeviceProvidersService,
    private readonly applicationDataService: ApplicationDataService,
  ) {
    this.applicationDataService.application$
      .pipe(
        filter((appData) => !!appData.application?.id),
        map((appData) => (appData.application as IotApplicationModel).id),
        distinctUntilChanged(),
        mergeMap((applicationId) =>
          this.deviceProvidersService.getAllDeviceProvidersForApplication({
            applicationId: applicationId,
            xApiVersion: CoreConstants.API_VERSION,
          }),
        ),
        catchError(() => of([])),
      )
      .subscribe((deviceProviders) => {
        this._deviceProviders$.next(deviceProviders);
      });
  }

  get deviceProviders$() {
    return this._deviceProviders$.asObservable();
  }
}
