<dpdhl-breadcrumbs data-test-id="alertsBreadcrumbs"></dpdhl-breadcrumbs>

<div class="p-24 bg-white" *ngIf="viewConfiguration">
  <div class="flex items-center space-x-8 mr-8">
    <div class="flex flex-grow items-center space-x-8">
      <svg-icon
        svgClass="h-16"
        src="assets/images/CoMo/alert-default-icon.svg"
        data-test-id="alertsSvgHeaderIcon"
      >
      </svg-icon>
      <h2 class="font-black-condensed text-headline1 pl-4">
        {{ 'dashboard.alert.overview' | translate | uppercase }}
      </h2>
      <svg-icon
        svgClass="helper-text w-16 h-16"
        title="{{ helpText | translate }}"
        src="assets/images/CoMo/info_outline.svg"
        data-test-id="infobox"
      >
      </svg-icon>
    </div>
    <div *ngIf="isGridLoading" class="spinner">
      <div class="spinner-icon"></div>
    </div>
  </div>
  <ng-container>
    <div class="pb-16">
      <dpdhl-tabs
        [active]="selectedTab"
        data-test-id="alertsTabControl"
        (select)="alertTabSelected($event)"
      >
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabUnresolved"
          title="{{ 'dashboard.alert.tab.unresolvedHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.unresolved' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ unResolvedAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabResolved"
          title="{{ 'dashboard.alert.tab.resolvedHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.resolved' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ resolvedAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabAll"
          title="{{ 'dashboard.alert.tab.allHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.all' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ totalAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
      </dpdhl-tabs>
    </div>
  </ng-container>
  <app-alert-list-table
    [alertData]="alertData"
    [isLoading]="isPageLoading"
    [selectableFacilities]="selectableFacilities"
    [viewConfiguration]="viewConfiguration"
    [workFlowFacilities]="workFlowFacilities"
    [showAreaLink]="viewConfiguration.predictiveMaintenance"
    [hasAlertUpdatePermissionFacilities]="hasAlertUpdatePermissionFacilities"
    [alertAction]="alertAction"
    (selectAlertAction)="onSelectAlertActionRow($event)"
    (updatedAlertWorkflow)="onUpdateAlertStatus($event)"
    (closeAlertWorkflow)="onCloseAlertStatus()"
  ></app-alert-list-table>
</div>
