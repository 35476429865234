<form [formGroup]="formGroup">
  <div class="flex items-center justify-end space-x-24">
    <dpdhl-date-range-picker
      [_formGroup]="formGroup"
      [minDate]="minDate"
      [maxDate]="maxDate"
      _formControlName="dateRange"
      label="{{ 'alert.dateRange' | translate }}"
      [placement]="'left'"
      [class]="'w-[16rem]'"
      [container]="'body'"
    ></dpdhl-date-range-picker>

    <dpdhl-button variant="secondary" (buttonClick)="resetFilters()">{{
      'alert.resetDateFilter' | translate
    }}</dpdhl-button>
  </div>
</form>
