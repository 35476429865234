<div class="px-24 space-y-16">
  <div class="flex items-center justify-between space-x-24 w-3/4">
    <div class="w-4/6">
      <dpdhl-date-range-picker
        [_formGroup]="dataService.formGroup"
        label="{{ 'predictiveMaintenance.history.dateRange' | translate }}"
        _formControlName="dateRange"
        class="w-1/3 mr-32"
      ></dpdhl-date-range-picker>
    </div>

    <div class="w-2/6">
      <div class="text-footnote text-secondary">
        {{ 'predictiveMaintenance.frequencyAnalysis.colorContrast' | translate }}
      </div>
      <dpdhl-slider
        [_formGroup]="dataService.formGroup"
        _formControlName="colorContrast"
        [showThumbLabel]="true"
        [min]="2"
        [max]="20"
      ></dpdhl-slider>
    </div>
  </div>
</div>
<div class="grid grid-cols-4 space-12">
  <div class="col-span-4 h-full px-24">
    <dpdhl-tabs [active]="selectedTab" (select)="tabSelected($event)" data-test-id="tabs-element">
      <dpdhl-tab-element *dpdhlTabElement>{{
        'predictiveMaintenance.frequencyAnalysis.frequencyTriggeringFactors' | translate
      }}</dpdhl-tab-element>
      <dpdhl-tab-element *dpdhlTabElement>{{
        'predictiveMaintenance.frequencyAnalysis.frequencyCellAnalysis' | translate
      }}</dpdhl-tab-element>
    </dpdhl-tabs>
  </div>

  @if (diagramModels.length > 0) {
    <div
      class="col-span-4 h-full px-24 relative diagram"
      [hidden]="selectedTab !== 1 || dataService.loading"
    >
      <div class="grid grid-cols-2 space-12">
        <app-cell-analysis
          class="pt-8"
          [diagramModel]="diagramModels[0]"
          [cellFrequencyData]="diagramModels[0].cellFrequencyData[0]"
          [showFrequencyAxis]="false"
          data-test-id="cell-analysis-left"
        ></app-cell-analysis>
        @if (diagramModels.length > 1) {
          <app-cell-analysis
            class="pt-8"
            [diagramModel]="diagramModels[1]"
            [cellFrequencyData]="diagramModels[1].cellFrequencyData[0]"
            [showFrequencyAxis]="false"
            data-test-id="cell-analysis-right"
          ></app-cell-analysis>
        }
      </div>
      <div
        class="frequency-range flex flex-col-reverse justify-between items-end pl-24 absolute top-16 bottom-40 pb-20 text-body2"
        data-testid="frequencyRangeContainer"
      >
        <div
          *ngFor="let frequency of diagramModels[0].frequencyRangeString"
          class="relative leading-4"
        >
          <span>{{ frequency }} Hz</span>
          <span class="line w-12 absolute top-1/2 -right-16 bg-gray-200"></span>
        </div>
      </div>
    </div>
  }

  @if (noiseDiagramModels.length > 0) {
    <div class="col-span-4 h-full px-24" [hidden]="selectedTab !== 0 || dataService.loading">
      <div class="text-headline1 mt-16 h-32">
        {{ 'predictiveMaintenance.frequencyAnalysis.alertFrequency' | translate }}
      </div>

      <div class="relative diagram">
        <div class="grid grid-cols-2 space-12">
          <app-cell-analysis
            class="pt-8"
            [diagramModel]="noiseDiagramModels[0]"
            [cellFrequencyData]="noiseDiagramModels[0].cellFrequencyData[0]"
            [showFrequencyAxis]="false"
            data-test-id="triggering-factors-alert-left"
          ></app-cell-analysis>
          @if (noiseDiagramModels.length > 1) {
            <app-cell-analysis
              class="pt-8"
              [diagramModel]="noiseDiagramModels[1]"
              [cellFrequencyData]="noiseDiagramModels[1].cellFrequencyData[0]"
              [showFrequencyAxis]="false"
              data-test-id="triggering-factors-alert-right"
            ></app-cell-analysis>
          }
        </div>
        <div
          class="frequency-range flex flex-col-reverse justify-between items-end absolute top-16 bottom-12 pb-16 text-body2"
          data-testid="noiseFrequencyRangeContainer"
        >
          <div
            *ngFor="let frequency of noiseDiagramModels[0].frequencyRangeString"
            class="relative leading-4"
          >
            <span>{{ frequency }} Hz</span>
            <span class="line w-12 absolute top-1/2 -right-16 bg-gray-200"></span>
          </div>
        </div>
      </div>
      <div class="text-headline1 mt-16 h-32">
        {{ 'predictiveMaintenance.frequencyAnalysis.warningFrequency' | translate }}
      </div>

      <div class="relative diagram">
        <div class="grid grid-cols-2 space-12">
          <app-cell-analysis
            class="pt-8"
            [diagramModel]="noiseDiagramModels[0]"
            [cellFrequencyData]="noiseDiagramModels[0].cellFrequencyData[1]"
            [showFrequencyAxis]="false"
            data-test-id="triggering-factors-warning-left"
          ></app-cell-analysis>
          @if (noiseDiagramModels.length > 1) {
            <app-cell-analysis
              class="pt-8"
              [diagramModel]="noiseDiagramModels[1]"
              [cellFrequencyData]="noiseDiagramModels[1].cellFrequencyData[1]"
              [showFrequencyAxis]="false"
              data-test-id="triggering-factors-warning-right"
            ></app-cell-analysis>
          }
        </div>
        <div
          class="frequency-range flex flex-col-reverse justify-between items-end absolute top-16 bottom-12 pb-16 text-body2"
          data-testid="noiseFrequencyRangeContainer"
        >
          <div
            *ngFor="let frequency of noiseDiagramModels[0].frequencyRangeString"
            class="relative leading-4"
          >
            <span>{{ frequency }} Hz</span>
            <span class="line w-12 absolute top-1/2 -right-16 bg-gray-200"></span>
          </div>
        </div>
      </div>
    </div>
  }
</div>
@if (dataService.loading) {
  <div class="w-full h-full px-24">
    <dpdhl-skeleton-card [classes]="['frequency-loading']"
      ><p class="p-8 font-bold-italic text-secondary text-body1">
        {{ 'predictiveMaintenance.frequencyAnalysis.loading' | translate }}
      </p></dpdhl-skeleton-card
    >
  </div>
}
