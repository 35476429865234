<div class="flex items-center pl-12 py-16" [hidden]="loading">
  <div
    data-test-id="test-overlay-date"
    class="flex"
    (mousemove)="mouseMove($event)"
    (mouseleave)="overlayDateMouseLeave()"
  >
    <div>
      <app-frequency-diagram
        [frequencyRange]="diagramModel.frequencyRangeString"
        [loadFrequencyDiagram]="loadFrequencyDiagram"
        [showScale]="false"
        [showXAxis]="false"
        [showYAxis]="showFrequencyAxis"
        [hidden]="diagramModel.frequencyDataCellTimes.length === 0"
        (diagramLoaded)="onDiagramLoad()"
        #frequencyImage
      >
      </app-frequency-diagram>

      <div class="relative -mt-32 h-24">
        @if (
          (diagramModel.alertMarkGroups | keyvalue)?.length &&
          diagramModel.frequencyDataCellTimes.length &&
          !diagramLoading
        ) {
          @for (remark of diagramModel.alertMarkGroups | keyvalue; track remark.key) {
            <div
              class="group has-tooltip"
              [style.margin-left.px]="getMarginForImage(remark.key)"
              data-testid="test-alert-remarks"
            >
              <dpdhl-svg-icon
                class="cursor-pointer"
                [svgClass]="'absolute'"
                [src]="'/assets/images/CoMo/pipe.svg'"
              >
              </dpdhl-svg-icon>
              <div
                class="custom-infobox shadow-lg bg-white overflow-auto rounded-4 text-body2 w-auto"
                [ngClass]="getMarginForImage(remark.key) > 300 ? 'tooltip-left' : 'tooltip-right'"
              >
                <div
                  class="flex items-center justify-items-center bg-gray-100 px-8 uppercase space-x-16 py-8"
                >
                  <span class="flex-grow font-bold text-body1 whitespace-nowrap"
                    >Date: {{ remark.key | date: 'dd.MM.yyyy' }}</span
                  >
                  <dpdhl-svg-icon [src]="'/assets/images/CoMo/info_outline.svg'"></dpdhl-svg-icon>
                </div>
                <div class="p-8 grid grid-cols-7 gap-y-12 dpdhl-text-secondary">
                  <dpdhl-svg-icon
                    [svgClass]="'w-20'"
                    [src]="'assets/images/CoMo/cellalert-default-icon.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">New Alerts:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.NewAlert, remark.value) }}
                  </div>

                  <dpdhl-svg-icon
                    [svgClass]="'w-20'"
                    [src]="'assets/images/CoMo/Alert_Circle.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">New Warnings:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.NewWarning, remark.value) }}
                  </div>

                  <dpdhl-svg-icon
                    [svgClass]="'w-20 opacity-50'"
                    [src]="'assets/images/CoMo/cellalert-default-icon.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">Severity changed to Alert:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.SeverityToAlert, remark.value) }}
                  </div>

                  <dpdhl-svg-icon
                    [svgClass]="'w-20 opacity-50'"
                    [src]="'assets/images/CoMo/Alert_Circle.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">Severity changed to Warning:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.SeverityToWarning, remark.value) }}
                  </div>

                  <dpdhl-svg-icon
                    [svgClass]="'w-20'"
                    [src]="'assets/images/CoMo/paper_registration.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">Manually resolved:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.ManuallyResolved, remark.value) }}
                  </div>

                  <dpdhl-svg-icon
                    [svgClass]="'w-20'"
                    [src]="'assets/images/CoMo/completed.svg'"
                  ></dpdhl-svg-icon>
                  <div class="col-span-5">Auto resolved:</div>
                  <div class="text-right">
                    {{ getCountForAlertType(CellAlertStatus.AutomaticallyResolved, remark.value) }}
                  </div>
                </div>
              </div>
            </div>
          }
        }
      </div>
      <span
        [hidden]="!loading && diagramModel.frequencyDataCellTimes.length > 0"
        class="text-secondary"
        >{{ 'room.chart.noData' | translate }}</span
      >
    </div>
  </div>
</div>

@if (!diagramLoading) {
  <div
    class="h-16 ml-16"
    [class.mt-8]="!!(diagramModel.alertMarkGroups | keyvalue)?.length"
    [class.-mt-24]="!(diagramModel.alertMarkGroups | keyvalue)?.length"
  >
    {{ mouseOverText }}
  </div>
}
